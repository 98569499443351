import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container, CssBaseline } from '@mui/material';
import LandingPage from './LandingPage';
import Chatbot from './Chatbot';
import QABot from './QABot';

function App() {
  return (
    <Router>
      <CssBaseline />
      <Container maxWidth="lg" className='main-container'>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/chatbot" element={<Chatbot />} />
          <Route path="/qabot" element={<QABot />} />
        </Routes>
      </Container>
    </Router>
  );
}

export default App;

