import React, { useState } from 'react';
import { TextField, Button, CircularProgress, Paper, Typography } from '@mui/material';
import './styles.css';

const Chatbot = () => {
  const [chatLog, setChatLog] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleSendMessage = async () => {
    if (userInput.trim() === '') return; // Do not send empty messages

    // Add user's message to the chat log
    const newMessage = {
      sender: 'user',
      text: userInput,
    };
    setChatLog(prevChatLog => [...prevChatLog, newMessage]);
    setLoading(true);
    
    // Make POST request to the chat_query API
    try {
      const response = await fetch('/chat_query', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_input: userInput }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const botResponse = await response.json();

      // Add bot's response to the chat log
      const newBotMessage = {
        sender: 'bot',
        text: botResponse,
      };
      setChatLog(prevChatLog => [...prevChatLog, newBotMessage]);
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setLoading(false);
      // Clear user input after sending the message
      setUserInput('');
    }
  };

  return (
    <Paper elevation={3} className='chatbot-container'>
      <div className='chat-log'>
        {chatLog.map((message, index) => (
          <div key={index} className='message'>
            {message.sender === 'user' ? (
              <strong>You:</strong>
            ) : (
              <strong>Bot:</strong>
            )}
            <span className='message-text'>{message.text}</span>
          </div>
        ))}
        {loading && (
          <div className="loading-message">
            <CircularProgress size={20} />
            <Typography variant="caption" fontStyle="italic">
              Waiting for bot response...
            </Typography>
          </div>
        )}
      </div>
      <div className="input-container">
        <TextField
          type="text"
          value={userInput}
          onChange={handleInputChange}
	  onKeyPress={(e) => {
	    if (e.key == 'Enter' && !loading) {
              handleSendMessage();
            }
	  }}
          placeholder="Type your message..."
          style={{ marginRight: '8px', width: '70%' }}
	  disabled={loading}
        />
        <Button onClick={handleSendMessage} disabled={loading}>Send</Button>
      </div>
    </Paper>
  );
};

export default Chatbot;

