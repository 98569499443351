import React from 'react';
import { Link } from 'react-router-dom';

function LandingPage() {
  return (
    <div>
      <h1>NLP Demo</h1>
      
      <p>
        Welcome to my NLP demo! This project provides a lightweight API and UI
        for question answering and chat bots powered by distilled NLP models.
      </p>

      <Link to="/chatbot">
        <button>Try the Chatbot</button>
      </Link>

      <Link to="/qabot">
        <button>Try the QA Bot</button>
      </Link>
      
    </div>
  );
}

export default LandingPage;
