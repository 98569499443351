import React, { useState } from 'react';
import { TextField, Button, CircularProgress, Paper, Typography, TextareaAutosize } from '@mui/material';
import './styles.css';

const QABot = () => {
  const [qaLog, setQALog] = useState([]);
  const [contextInput, setContextInput] = useState('');
  const [userQuestion, setUserQuestion] = useState('');
  const [loading, setLoading] = useState(false);

  const handleContextInputChange = (e) => {
    setContextInput(e.target.value);
  };

  const handleQuestionInputChange = (e) => {
    setUserQuestion(e.target.value);
  };

  const handleSendQuestion = async () => {
    if (contextInput.trim() === '' || userQuestion.trim() === '') return; // Do not send empty inputs

    setLoading(true);

    // Make POST request to the qa_query API
    try {
      const response = await fetch('/qa_query', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ context: contextInput, question: userQuestion }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const botAnswer = await response.json();

      // Add QA entry to the log
      const newQAEntry = {
        context: contextInput,
        question: userQuestion,
        answer: botAnswer,
      };
      setQALog((prevQALog) => [...prevQALog, newQAEntry]);

      // Clear user question input after sending the question
      setUserQuestion('');
    } catch (error) {
      console.error('Error sending question:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper elevation={3} className='qabot-container'>
      <div className='context-input-container'>
        <Typography variant='subtitle1'>Context:</Typography>
        <TextareaAutosize
          value={contextInput}
          onChange={handleContextInputChange}
          placeholder="Type the context..."
          style={{ marginBottom: '8px', width: '100%', height: '300px' }}
        />
      </div>
      <div className='input-container'>
        <Typography variant='subtitle1'>Question:</Typography>
        <TextField
          type="text"
          value={userQuestion}
          onChange={handleQuestionInputChange}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && !loading) {
              handleSendQuestion();
            }
          }}
          placeholder="Type your question..."
          style={{ marginRight: '8px', width: '70%' }}
          disabled={loading}
        />
        <Button onClick={handleSendQuestion} disabled={loading}>
          Ask
        </Button>
      </div>
      <div className='qa-log'>
        {qaLog.map((entry, index) => (
          <div key={index} className='qa-entry'>
            <Typography variant='subtitle1'>Question:</Typography>
            <Typography>{entry.question}</Typography>
            {entry.answer && (
              <div>
                <Typography variant='subtitle1'>Answer:</Typography>
                <Typography>{entry.answer}</Typography>
              </div>
            )}
          </div>
        ))}
        {loading && (
          <div className="loading-message">
            <Typography variant='caption' fontStyle='italic'>
              Waiting for answer...
            </Typography>
          </div>
        )}
      </div>
    </Paper>
  );
};

export default QABot;
